<template>
  <v-container fluid>
    <v-card elevation="4" class="mt-0">
      <v-tabs v-model="tab" @change="tabChanged(tab)">
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <all-tab v-if="item.content == 'main'"></all-tab>
          <checkout-tab v-if="item.content == 'checkout'"></checkout-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    AllTab: () => import("./orders-components/AllTab.vue"),
    CheckoutTab: () => import("./orders-components/CheckoutTab.vue"),
  },

  data() {
    return {
      tab: null,
      items: [
        { tab: this.$t("order"), content: "main" },
        { tab: this.$t("checkout"), content: "checkout" },
      ],
    };
  },

  methods: {
    async tabChanged(index) {
      try {
        if (this.items[index].content == "checkout")
          this.$store.commit("orders/CLEAN_LIST");
        if (this.items[index].content == "main")
          this.$store.dispatch("orders/list", { page: 1, itemsPerPage: 30 });
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
